import React, { useState, useEffect, useRef } from "react";
import Slider from "common/slider";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import { Link } from "react-router-dom";
import VideoSlider from "./videoSlider";
import PhotoSelider from "./photoSlider";
import OfferSlider from "./offerSlider";
import JobsSelider from "./jobsSlider";
import DocumentSelider from "./documentSlider";
import Popupmodels from "./popupModels";
import apiEndPoints from "utils/apiEndPoints";
import { apiCall } from "utils/httpClient";
import { successToast, errorToast } from "utils/httpClient";
import OtpInput from "react18-input-otp";
import Details from "./details";
import LogoSlider from "./logoSlider";

//import { Rating } from 'react-simple-star-rating'

function ServiceDetails(props) {
  const formRef = useRef();
  const { businessServiceDetail } = props;
  const [slectedTab, onChangeTab] = useState(5);
  const [slectedPopupType, onChangePopupType] = useState(0);
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  const [showFilePath, setShowFilePath] = useState("");
  const [showFileTitle, setShowTitle] = useState("Title");
  const [mobileError, setMobileError] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [rating, setRating] = useState(0);
  const [abouthiden, setAbouthiden] = useState(true);
  const [servicelist, setServicelist] = useState("");
  const [paymentList, setPaymentList] = useState("");
  const [showIcon, setshowIcon] = useState(false);
  const [bucket_Img_url, setBucket_Img_url] = useState();
  const [openModal, setOpenModal] = useState(false)


  const handleRating = (rate) => {
    setRating(rate);
  };
  const seemore = (status) => {
    setAbouthiden(status);
  };

  useEffect(() => {
    businessServiceDetail?.photofile &&
      getImage(businessServiceDetail.photofile);
  }, []);

  useEffect(() => {
    const stringData =
      businessServiceDetail?.servicedata &&
      businessServiceDetail?.servicedata.reduce((result, item) => {
        return `${result}${item.title},`;
      }, "");

    setServicelist(stringData);

    const paydata = businessServiceDetail?.payments
      ? JSON.parse(businessServiceDetail?.payments)
      : {};
    setPaymentList(paydata);
  }, []);

  function mobileValidation() {
    let mobileError = {};
    let formErrorData = false;
    console.log("mobileValidation -> mobile", mobile);

    const emailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!mobile || emailValid.test(mobile) === false) {
      formErrorData = true;
      mobileError["mobile"] = "Please Entery Valid Email address";
    }

    // else if (mobile.length != 10) {
    //   formErrorData = true;
    //   mobileError["mobile"] = "Enter 10-digits Mobile Number";
    // }
    setMobileError(mobileError);
    return formErrorData;
  }

  const handleAddMobile = async () => {
    if (!mobileValidation()) {
      setIsLoader(true);
      const param = {
        email: mobile,
        businessid: businessServiceDetail.businessid,
      };
      console.log("param", param);
      const { data } = await apiCall("POST", apiEndPoints.ADDUSERMOBILE, param);
      if (data.status == 200) {
        setshowIcon(true)
        // getMobileResponse(data.data)
        // successToast(data.message);
        onChangePopupType(1);
        setIsLoader(false);
        // setMobileStatus(1)
        //setMobile('')
      } else {
        errorToast(data.message);
        setIsLoader(false);
        setMobile("");
      }
    }
  };

  const handleEdit = () => {
    onChangePopupType(0)
  }


  const handleOpenLogo = () => {
    setOpenModal(true)
  }

  // ------------------------------- verify OTP ------------------
  const [otp, setOtp] = useState(null);
  const [otpError, setOtpError] = useState({});
  const [verifyOtpData, setVerifyOtpData] = useState({});

  function otpValidation() {
    let error = {};
    let formErrorData = false;
    if (!otp) {
      formErrorData = true;
      error["otp"] = "OTP is required";
    }
    setOtpError(error);
    return formErrorData;
  }

  const verifyOtp = async () => {
    if (!otpValidation()) {
      setIsLoader(true);
      const header = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const params = {
        mobile: mobile,
        otp: otp,
      };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.USERMOBILEOTPVERIFY,
        params,
        header
      );
      if (data.status == 200) {
        setVerifyOtpData(data.data);
        setAddReview({ ...data.data });
        // successToast(data.message?.messageTost);
        onChangePopupType(2);
        setOtpError(data.message);
        setIsLoader(false);
        setOtp(null);
      } else {
        setOtpError(data.message);
        errorToast(data.message?.messageTost);
        setIsLoader(false);
      }
    }
  };
  // ------------------------------------- Resend Mobile OTP--------------------------
  const [isOtpLoader, setIsOtpLoader] = useState(false);
  const resendMobileOtp = async () => {
    setIsOtpLoader(true);
    const header = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    const params = {
      mobile: mobile,
      countrycode: countryCode,
    };
    const { data } = await apiCall(
      "POST",
      apiEndPoints.RESENDMOBILEOTP,
      params,
      header
    );
    if (data.status == 200) {
      // setVerifyOtpData(data.data);
      // setAddReview({ ...data.data });
      successToast(data.message.messageTost);
      setIsOtpLoader(false);
      setOtpError(data.message);
      // onChangePopupType(2);
    } else {
      errorToast(data.message?.messageTost);
      setIsOtpLoader(false);
    }
  };

  // ------------------------------ Add reviews -----------------------------------
  const [addReview, setAddReview] = useState({
    firstname: "",
    lastname: "",
    review: "",
  });
  const [reviewError, setReviewError] = useState({});

  const [reviewDetails, setReviewDetails] = useState({});

  const handleReviewInput = (e) => {
    setAddReview({ ...addReview, [e.target.name]: e.target.value });
  };

  function reviewValidation() {
    let error = {};
    let formErrorData = false;
    if (!addReview.firstname) {
      formErrorData = true;
      error["firstname"] = "First Name is required";
    }
    if (!addReview.lastname) {
      formErrorData = true;
      error["lastname"] = "Last Name is required";
    }
    if (!rating) {
      formErrorData = true;
      error["rating"] = "Rating is required";
    }
    if (!addReview.review) {
      formErrorData = true;
      error["review"] = "Description is required";
    }
    setReviewError(error);
    return formErrorData;
  }

  const sumitReview = async () => {
    if (!reviewValidation()) {
      setIsLoader(true);
      const header = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const params = {
        businessid: businessServiceDetail.businessid,
        profileid: verifyOtpData.profileid,
        mobile: mobile,
        firstname: addReview.firstname,
        lastname: addReview.lastname,
        rating: rating,
        review: addReview.review,
      };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.ADDREVIEW,
        params,
        header
      );
      if (data.status == 200) {
        setMobile("")
        successToast(data.message);
        document.getElementById("button").click();
        setIsLoader(false);
        onChangePopupType(0);
        setRating(0);

        props.setBusinessServiceDetail({
          ...businessServiceDetail,
          total_reviews: data?.data?.total_reviews,
          reviews: data?.data?.reviews,
          avg_rating: data?.data?.avg_rating,
        });
        // props.getServicesDetails();
        // setMobile("")
        formRef.current.reset();
      } else {
        errorToast(data.message);
        setIsLoader(false);
      }
    }
  };

  async function getImage(param) {
    setIsLoader(true);
    const params = {
      fileName: param,
    };
    const { data } = await apiCall("POST", apiEndPoints.GETIMAGE, params);
    if (data.status == 200) {
      setBucket_Img_url(data.url);
      // setTimeout(() => {
      setIsLoader(false);
      // }, 500);
    } else {
    }
  }



  return (
    <>
      <div
        className="modal fade addReview--modal"
        id="addReview"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="container">
          <div className="modal-dialog">
            <div className="modal-content  tab-content">
              <div className="tab-pane active" id="activity">
                <div className="modal-content modal_content_box">
                  <div className="modal-header bdr0 bdr0 modal_header bdr0 ">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Add Your review
                    </h5>
                    <button
                      id="button"
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="progress-bar"></div>
                  </div>
                  <div className="modal-body">
                    <form ref={formRef}>
                      <div
                        className="row"
                        style={
                          [0, 1].includes(slectedPopupType)
                            ? {}
                            : { display: "none" }
                        }
                      >
                        <div style={{ display: "inline" }}>
                          <div className="col-md-12  form-group">
                            <label
                              className="d-block mb-0"
                              style={{ marginBottom: "10px!important" }}
                            >
                              Email
                            </label>
                            <div className="form-control form-control-md d-flex justify-content-between "
                              style={{
                                padding: "0px 0px 0px 10px",
                              }}>

                              <input
                                style={{
                                  background: "white", border: "none"
                                }}
                                placeholder="Enter your email"
                                value={mobile}
                                onChange={(e) => {
                                  setMobile(e.target.value.trimStart());
                                  setMobileError({ ...mobileError, mobile: "" });
                                }}
                                // maxLength="10"
                                // onKeyPress={(event) => {
                                //   if (
                                //     !/[0-99]/.test(event.key) ||
                                //     event.target.value.length > 9
                                //   ) {
                                //     event.preventDefault();
                                //   }  
                                // }}
                                disabled={slectedPopupType == 1 ? true : false}

                              />
                              {showIcon && (
                                <i class="fas fa-edit mt-1" style={{ margin: "0px 10px 0px 0px;" }} onClick={handleEdit}></i>
                              )}
                            </div>
                            <small
                              className="text-danger mt-4"
                            /*  style={{ marginLeft: "-460px" }} */
                            >
                              {mobileError?.mobile ? mobileError?.mobile : ""}
                            </small>
                          </div>

                        </div>
                      </div>
                      <div
                        className="row"
                        id="otpFild"
                        style={
                          slectedPopupType === 1 ? {} : { display: "none" }
                        }
                      >
                        <div
                          className="col-md-12 form-group"
                          style={{ marginLeft: "18px" }}
                        >
                          <label className="d-block mb-0">
                            One Time Password
                          </label>
                          {/* <input
                            type="text"
                            className="form-control form-control-md"
                            placeholder="OTP"
                          /> */}
                          <OtpInput
                            value={otp}
                            onChange={(e) => {
                              setOtp(e);
                              setOtpError({
                                ...otpError,
                                messageSuccess: "",
                              });
                            }}
                            numInputs={4}
                            separator={<span>&#160;</span>}
                            inputStyle={{ width: "40px", height: "40px" }}
                          />
                          <small className="text-danger">
                            {otpError?.otp ? otpError.otp : ""}
                          </small>
                          <small className="text-success">
                            {otpError?.messageSuccess
                              ? otpError.messageSuccess
                              : ""}
                          </small>
                          
                        </div>
                        <div className="col-md-12 ml-3 text-left form-group">
                          <a
                            href="#activity"
                            className="resentOTp"
                            data-toggle="tab"
                            onClick={resendMobileOtp}
                          >
                            {isOtpLoader ? (
                              <img
                                src="images/loader_dark.gif"
                                style={{ width: "30px" }}
                              />
                            ) : (
                              "Resend One Time Password"
                            )}
                          </a>
                          <br />
                        <div className="check-email-inbox">
                            <span>We've sent you a 4 digit code to your Inbox, please enter it to validate your email. Check also your Junk, Spam or Trash folder.</span>
                          </div>
                        </div>
                      </div>
                      <div
                        id="reviewForm"
                        style={slectedPopupType >= 2 ? {} : { display: "none" }}
                      >
                        <div className="row">
                          <div className="col-md-6  form-group">
                            <label className="d-block mb-0">First Name</label>
                            <input
                              type="text"
                              name="firstname"
                              className="form-control form-control-md"
                              placeholder="First Name"
                              onChange={handleReviewInput}
                              value={addReview.firstname}
                            />
                            <small className="text-danger">
                              {!addReview.firstname && reviewError?.firstname
                                ? reviewError?.firstname
                                : ""}
                            </small>
                          </div>
                          <div className="col-md-6  form-group">
                            <label className="d-block mb-0">Last Name</label>
                            <input
                              type="text"
                              name="lastname"
                              className="form-control form-control-md"
                              placeholder="Last Name"
                              onChange={handleReviewInput}
                              value={addReview.lastname}
                            />
                            <small className="text-danger">
                              {!addReview.lastname && reviewError?.lastname
                                ? reviewError?.lastname
                                : ""}
                            </small>
                          </div>
                          <div className="form-group col-md-12 mb-0">
                            <label className="d-block mb-0">Rating</label>
                            <div className="serv--rate">
                              <Rating
                                name="rating"
                                onClick={handleRating}
                                ratingValue={rating} /* Rating Props */
                              />
                            </div>
                            <small className="text-danger">
                              {!rating && reviewError?.rating
                                ? reviewError?.rating
                                : ""}
                            </small>
                          </div>
                          <div className="form-group col-md-12">
                            <span className="d-block mb-0">Description</span>
                            <textarea
                              className="form-control form-control-md"
                              name="review"
                              placeholder="Description..."
                              // defaultValue={""}
                              onChange={handleReviewInput}
                            />
                            <small className="text-danger">
                              {!addReview.review && reviewError?.review
                                ? reviewError?.review
                                : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="modal-footer text-center dpBlk bdr0 bdr0 bdr0 bdr0 "
                    id="reviewBtmBtn"
                  >
                    {slectedPopupType === 0 ? (
                      <a
                        className="main-btn"
                        onClick={() => handleAddMobile()}
                        //onClick={handleAddMobile}
                        id="sendOtp"
                        style={{ width: 140 }}
                      >
                        {isLoader ? (
                          <img
                            src="images/loader_white.gif"
                            style={{ width: "30px" }}
                          />
                        ) : (
                          "Send OTP"
                        )}
                      </a>
                    ) : slectedPopupType === 1 ? (
                      <a
                        href="#"
                        className="main-btn"
                        id="verifyOtp"
                        // onClick={() => onChangePopupType(2)}
                        onClick={verifyOtp}
                        data-toggle="tab"
                      >
                        {isLoader ? (
                          <img
                            src="images/loader_white.gif"
                            style={{ width: "30px" }}
                          />
                        ) : (
                          "Verify"
                        )}
                      </a>
                    ) : (
                      slectedPopupType === 2 && (
                        <a
                          className="main-btn"
                          // href="#"
                          // onClick={() => onChangePopupType(0)}
                          onClick={sumitReview}
                          id="reviewFormSubmit"
                          style={{ width: 120 }}
                        >
                          {isLoader ? (
                            <img
                              src="images/loader_white.gif"
                              style={{ width: "30px" }}
                            />
                          ) : (
                            "Submit"
                          )}
                        </a>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className=" default-smb-area bg--light">
        <div className="service--data">
          {businessServiceDetail &&
            Object.keys(businessServiceDetail).length > 0 ? (
            businessServiceDetail?.allinformation === 1 ? (
              <div className="container">
                <div className="page-container border-bottom-0">
                  <div className="row">
                    <div className="page-content col-xl-8 mb-8 mb-xl-0">
                      <div className="row">
                        <div className="page-content col-xl-12 mb-12 mb-xl-0">
                          <div className="widget mb-8">
                            <div className="rm-listings">
                              <div className="card border-8 shadow mb-3 p-3">
                                <div className="card-body text-center mb-2">
                                  <div className="row align-items-center business-externo">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-left">
                                      <div className="row">
                                        <div
                                          className="col-md-3 col-12"
                                          style={{ margin: "auto" }}
                                        >

                                          <img
                                            src={
                                              bucket_Img_url
                                                ? bucket_Img_url
                                                : "images/no_image.png"
                                            }
                                            className="card-img rounded"
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                            }}

                                            onClick={() => handleOpenLogo()}
                                          />

                                          {/* <img
                                            src="images/site-web.png"
                                            style={{
                                              width: "80px",
                                              height: "auto",
                                            }}
                                          /> */}
                                        </div>
                                        <div className="col-md-9 col-12">
                                          <p className="business-externo__title">
                                            {businessServiceDetail.fullname}
                                          </p>
                                          <p
                                            className="business-externo__subtitle"
                                            style={{ marginTop: "-16px" }}
                                          >
                                            {/* Pricing Model:{" "}
                                            {businessServiceDetail.pricemodel} */}

                                            <small className="srvc--text serice--list-text">
                                              <b>Occupation:</b>&nbsp;
                                              {servicelist.substring(
                                                0,
                                                servicelist.length - 2
                                              )}
                                            </small>
                                          </p>
                                          <p
                                            className="business-externo__location"
                                            style={{
                                              fontSize: "18px",
                                              marginTop: "-10px",
                                            }}
                                          >
                                            {/* <i className="fa fa-map-marker"></i>{" "}
                                            <a
                                              target="_blank"
                                              href={`https://www.google.com/maps/search/${businessServiceDetail.address}`}
                                            >
                                              {businessServiceDetail.address}
                                            </a> */}

                                            <small className="srvc--text serice--list-text">
                                              <b>City:</b>&nbsp;
                                              {businessServiceDetail?.city}
                                              ,&nbsp;
                                              {
                                                businessServiceDetail?.state_name
                                              }
                                            </small>

                                            <div>
                                              <small className="srvc--text serice--list-text">
                                                <b>Industry:</b>&nbsp;
                                                {
                                                  businessServiceDetail.industry_name
                                                }
                                              </small>
                                            </div>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-right">
                                      {businessServiceDetail.showtext > 0 ? (
                                        <label className="business__card-badge mr-1">
                                          <Link
                                            to="#"
                                            onClick={(e) => {
                                              window.location = `sms:${businessServiceDetail.phone}?&body=<<Type here the information you want to request from the business>>`;
                                              e.preventDefault();
                                            }}
                                          >
                                            <p className="business__card-badge--p p__text">
                                              <i className="fa fa-comments"></i>{" "}
                                              <small
                                                className="p__text"
                                                style={{ fontSize: "15px" }}
                                              >
                                                Text
                                              </small>
                                            </p>
                                          </Link>
                                        </label>
                                      ) : (
                                        ""
                                      )}

                                      {businessServiceDetail.showcall > 0 ? (
                                        <label className="business__card-badge mr-1">
                                          <Link
                                            to="#"
                                            onClick={(e) => {
                                              window.location = `tel:${businessServiceDetail.phone}`;
                                              e.preventDefault();
                                            }}
                                          >
                                            <p className="business__card-badge--p">
                                              <i className="fa fa-phone"></i>{" "}
                                              <small
                                                className="p__text"
                                                style={{ fontSize: "15px" }}
                                              >
                                                Call
                                              </small>
                                            </p>
                                          </Link>
                                        </label>
                                      ) : (
                                        ""
                                      )}

                                      {businessServiceDetail.showemail > 0 ? (
                                        <label className="business__card-badge mr-1">
                                          <a
                                            target="_blank"
                                            href={`mailto:${businessServiceDetail.email}?&subject=New Lead from MAXBIZ&body=<<Type here the information you want to request from the business>>`}
                                          >
                                            <p className="business__card-badge--p">
                                              <i className="fa fa-envelope"></i>{" "}
                                              <small
                                                className="p__text"
                                                style={{ fontSize: "15px" }}
                                              >
                                                Email
                                              </small>
                                            </p>
                                          </a>
                                        </label>
                                      ) : (
                                        ""
                                      )}

                                      <label className="business__card-badge mr-1">
                                        <a
                                          target="_blank"
                                          href={
                                            businessServiceDetail.websiteurl
                                          }
                                        >
                                          <p className="business__card-badge--p">
                                            <i class="fa fa-globe"></i>{" "}
                                            <small
                                              className="p__text"
                                              style={{ fontSize: "15px" }}
                                            >
                                              Web
                                            </small>
                                          </p>
                                        </a>
                                      </label>

                                      <label className="business__card-badge mr-1">
                                        <a
                                          target="_blank"
                                          href={`https://www.google.com/maps/search/${businessServiceDetail.address}`}
                                        >
                                          <p className="business__card-badge--p">
                                            <i class="fa fa-map"></i>{" "}
                                            <small
                                              className="p__text"
                                              style={{ fontSize: "15px" }}
                                            >
                                              Map
                                            </small>
                                          </p>
                                        </a>
                                      </label>
                                      <label className="business__card-badge mr-1">
                                        <a target="_blank" href={`mailto:mbt@maxelit.com?&subject=Suspicious Content&body=Dear MAXBIZ Team:%0D%0A%0D%0APlease review the following suspicious content: <<INCLUDE PAGE, SUSPICIOUS CONTENT AND ANY OTHER RELEVANT DETAILS>>.%0D%0A%0D%0ARegards,%0D%0A%0D%0A<<YOUR FULL NAME>>%0D%0A<<YOUR PHONE NUMBER>>`}>
                                          <p className="business__card-badge--p">
                                            <i class="fa fa-flag"></i> <small className="p__text" style={{ fontSize: "14px" }}>Report</small>
                                          </p>
                                        </a>
                                      </label>

                                      <div>
                                        {businessServiceDetail.is_nonprofit ==
                                          0 ? null : (
                                          <>
                                            <label
                                              className="form-check-label"
                                              htmlFor="flexCheckDefault"
                                            >
                                              Non-Profit
                                            </label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="widget mb-8">
                                  <div className="mb-3">
                                    <div className="srvc-dt-menu">
                                      <ul className="srvc--menu serdetail-ment">
                                        {businessServiceDetail?.plan_type == 1 ? (
                                          <>
                                            <li onClick={() => onChangeTab(5)}
                                              className={`srvc--gallary-menu cursor-pointer ${slectedTab === 5 &&
                                                "active-link"
                                                }`}
                                            >
                                              <span

                                                style={{ fontSize: "16px" }}
                                              >
                                                {/* <i className="fa fa-power-off"></i>{" "} */}
                                                Details
                                              </span>
                                            </li>
                                           
                                            <li
                                              className={`srvc--gallary-menu cursor-pointer ${slectedTab === 1 &&
                                                "active-link"
                                                }`}
                                            >
                                              <span
                                                onClick={() => onChangeTab(1)}
                                                style={{ fontSize: "16px" }}
                                              >
                                                {/* <i className="fa fa-power-off"></i>{" "} */}
                                                Photos
                                              </span>
                                            </li>

                                            <li
                                              className={`srvc--gallary-menu cursor-pointer ${slectedTab === 0 &&
                                                "active-link"
                                                }`}
                                            >
                                              {/* <img src="images/email.svg"></img> */}
                                              <span
                                                onClick={() => onChangeTab(0)}
                                                style={{ fontSize: "16px" }}
                                              >
                                                {/* <i className="fa fa-power-off"></i>{" "} */}
                                                Videos
                                              </span>
                                            </li>
                                            
                                            <li
                                              className={`srvc--gallary-menu cursor-pointer ${slectedTab === 4 &&
                                                "active-link"
                                                }`}
                                            >
                                              <span
                                                onClick={() => onChangeTab(4)}
                                                style={{ fontSize: "16px" }}
                                              >
                                                {/* <i className="fa fa-power-off"></i>{" "} */}
                                                Documents
                                              </span>
                                            </li>


                                          </>) : null}

                                        {businessServiceDetail?.plan_type ===
                                          2 ||
                                          businessServiceDetail?.plan_type ===
                                          3 ? (
                                          <>
                                            <li
                                              className={`srvc--gallary-menu cursor-pointer ${slectedTab === 5 &&
                                                "active-link"
                                                }`}
                                            >
                                              <span
                                                onClick={() => onChangeTab(5)}
                                                style={{ fontSize: "16px" }}
                                              >
                                                {/* <i className="fa fa-power-off"></i>{" "} */}
                                                Details
                                              </span>
                                            </li>
                                            
                                            <li
                                              className={`srvc--gallary-menu cursor-pointer ${slectedTab === 1 &&
                                                "active-link"
                                                }`}
                                            >
                                              <span
                                                onClick={() => onChangeTab(1)}
                                                style={{ fontSize: "16px" }}
                                              >
                                                {/* <i className="fa fa-power-off"></i>{" "} */}
                                                Photos
                                              </span>
                                            </li>

                                            <li
                                              className={`srvc--gallary-menu cursor-pointer ${slectedTab === 0 &&
                                                "active-link"
                                                }`}
                                            >
                                              {/* <img src="images/email.svg"></img> */}
                                              <span
                                                onClick={() => onChangeTab(0)}
                                                style={{ fontSize: "16px" }}
                                              >
                                                {/* <i className="fa fa-power-off"></i>{" "} */}
                                                Videos
                                              </span>
                                            </li>

                                            <li
                                              className={`srvc--gallary-menu cursor-pointer ${slectedTab === 4 &&
                                                "active-link"
                                                }`}
                                            >
                                              <span
                                                onClick={() => onChangeTab(4)}
                                                style={{ fontSize: "16px" }}
                                              >
                                                {/* <i className="fa fa-power-off"></i>{" "} */}
                                                Documents
                                              </span>
                                            </li>

                                            <li
                                              className={`srvc--gallary-menu cursor-pointer ${slectedTab === 2 &&
                                                "active-link"
                                                }`}
                                            >
                                              <span
                                                onClick={() => onChangeTab(2)}
                                                style={{ fontSize: "16px" }}
                                              >
                                                {/* <i className="fa fa-power-off"></i>{" "} */}
                                                Offers
                                              </span>
                                            </li>
                                            <li
                                              className={`srvc--gallary-menu cursor-pointer ${slectedTab === 3 &&
                                                "active-link"
                                                }`}
                                            >
                                              <span
                                                onClick={() => onChangeTab(3)}
                                                style={{ fontSize: "16px" }}
                                              >
                                                {/* <i className="fa fa-power-off"></i>{" "} */}
                                                Jobs
                                              </span>
                                            </li>
                                          </>
                                        ) : null}
                                      </ul>
                                    </div>
                                    <div className="srvc--gallery-detail mt-2">
                                      <div className="srvc--video">
                                        {businessServiceDetail?.plan_type ===
                                          1 || businessServiceDetail?.plan_type ===
                                          2 ||
                                          businessServiceDetail?.plan_type ===
                                          3 ? (
                                          <>
                                            {slectedTab === 1 ? (
                                              <PhotoSelider
                                                photoList={
                                                  businessServiceDetail.photo
                                                }
                                                setShowFilePath={
                                                  setShowFilePath
                                                }
                                                setShowTitle={
                                                  setShowTitle
                                                }
                                                documentUrl={props.documentUrl}
                                              />
                                            ) : slectedTab === 2 ? (
                                              <OfferSlider
                                                offerList={
                                                  businessServiceDetail.offers
                                                }
                                                setShowFilePath={
                                                  setShowFilePath
                                                }
                                                setShowTitle={
                                                  setShowTitle
                                                }
                                              />
                                            ) : slectedTab === 3 ? (
                                              <JobsSelider
                                                jobsList={
                                                  businessServiceDetail.jobs
                                                }
                                                setShowFilePath={
                                                  setShowFilePath
                                                }
                                              />
                                            ) : slectedTab === 4 ? (
                                              <DocumentSelider
                                                documentList={
                                                  businessServiceDetail.documents
                                                }
                                                setShowFilePath={
                                                  setShowFilePath
                                                }
                                                setShowTitle={
                                                  setShowTitle
                                                }
                                                documentUrl={props.documentUrl}
                                              />
                                            ) : slectedTab === 5 ? (
                                              <Details
                                                businessServiceDetail={
                                                  businessServiceDetail
                                                }
                                                paymentList={paymentList}
                                                servicelist={servicelist}
                                                abouthiden={abouthiden}
                                                seemore={seemore}
                                              />
                                            ) : (
                                              <VideoSlider
                                                videoList={
                                                  businessServiceDetail.video
                                                }
                                                setShowFilePath={
                                                  setShowFilePath
                                                }
                                                setShowTitle={
                                                  setShowTitle
                                                }
                                                documentUrl={props.documentUrl}
                                                showFilePath={showFilePath}
                                              />
                                            )}
                                          </>
                                        ) : (
                                          console.log("")
                                        )}
                                      </div>
                                      <div
                                        className="modal showVideo--modal"
                                        id="showVideo"
                                        tabIndex={-1}
                                        aria-labelledby="exampleModalLabel"
                                        aria-hidden="true"
                                      >
                                        <Popupmodels
                                          setShowFilePath={setShowFilePath}
                                          showFilePath={showFilePath}
                                          slectedTab={slectedTab}
                                          showFileTitle={showFileTitle}
                                        />
                                        {openModal ? <LogoSlider
                                          bucket_Img_url={bucket_Img_url}
                                          setOpenModal={setOpenModal}
                                        /> : ""}
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="page-content col-xl-4 mb-4 mb-xl-0">
                      <div className="card border-8 shadow mb-3">
                        <div className="card-header bg-white">
                          <div className="d-flex justify-content-between">
                            <p style={{ margin: "0" }}>
                              <small className="mr-1" style={{ margin: "0" }}>
                                Reviews
                              </small>
                              <span className="badge bg--smb">
                                {businessServiceDetail.total_reviews}
                              </span>
                            </p>


                            <div className="usr--head-rating">
                              <p style={{ margin: "0" }}>
                                <small className="mr-1" style={{ margin: "0" }}>Avg. Rating :</small>
                                <small className="mr-1" style={{ margin: "0" }}>{Math.round(businessServiceDetail?.avg_rating)}</small>
                              </p>
                              {/* <span
                                className={`mr-1 fa fa-star ${businessServiceDetail?.avg_rating >= 1
                                  ? "checked"
                                  : ""
                                  }`}
                              />
                              <span
                                className={`mr-1 fa fa-star ${businessServiceDetail?.avg_rating >= 2
                                  ? "checked"
                                  : ""
                                  }`}
                              />
                              <span
                                className={`mr-1 fa fa-star ${businessServiceDetail?.avg_rating >= 3
                                  ? "checked"
                                  : ""
                                  }`}
                              />
                              <span
                                className={`mr-1 fa fa-star ${businessServiceDetail?.avg_rating >= 4
                                  ? "checked"
                                  : ""
                                  }`}
                              />
                              <span
                                className={`fa fa-star ${businessServiceDetail?.avg_rating >= 5
                                  ? "checked"
                                  : ""
                                  }`}
                             
                              /> */}


                              <Rating initialValue={Math.round(businessServiceDetail?.avg_rating)} readonly="true" size="20" />
                            </div>
                          </div>
                        </div>
                        <div className="card-body" style={{ padding: 0, height: "472px", overflow: "auto" }}>
                          <ul className="srvc-block-lists">
                            {businessServiceDetail.reviews &&
                              businessServiceDetail.reviews.length > 0 ? (
                              businessServiceDetail.reviews.map((item, i) => {

                                return (
                                  <li key={i}>
                                    <div style={{ padding: "6px 12px" }}>
                                      <div className="row d-flex align-items-center">
                                        {/*   <div className="col-md-3 col-sm-2 col-2 mu-photo">
                                            <img
                                              // src={item.profile}
                                              src={
                                                item.aws_url
                                                  ? item.aws_url
                                                  : "images/no_image.png"
                                              }
                                              className="mu--usr-icon"
                                            />
                                          </div> */}
                                        <div className="col-md-12 col-sm-10 col-10 mu-content">
                                          <div className="mu--cont-data">
                                            <div className="d-flex justify-content-between">
                                              <h5 className="usr--name">
                                                {item.firstname
                                                  ? item.firstname + " " + item.lastname.charAt(0) + "."
                                                  : "" + " " + item.lastname
                                                    ? item.lastname
                                                    : ""}
                                              </h5>
                                            </div>
                                            <p className="mu-usr-text reviewtext">
                                              {item.description}
                                            </p>
                                            <div className="mb-2 d-flex justify-content-between">
                                              <p
                                                style={{
                                                  fontSize: "13px",
                                                  margin: 0,
                                                }}
                                              >
                                                <i className="fa fa-calendar-day"></i>{" "}
                                                <small className="mu--date">
                                                  {moment(item.createddate).format(
                                                    "D MMM YYYY h:mm A"
                                                  )}
                                                </small>
                                              </p>
                                              {/* <a className="mu-verify--btn">
                                                {item.verified == 1
                                                  ? "Verified"
                                                  : ""}{" "}
                                                <i className="fa fa-check-circle" />
                                              </a> */}
                                            </div>
                                            <hr
                                              style={{
                                                margin: "8px 0px",
                                                color: "#bfbaba",
                                              }}
                                            ></hr>
                                            <div className="d-flex justify-content-between">
                                              <p
                                                style={{
                                                  fontSize: "12px",
                                                  margin: 0,
                                                }}
                                              >
                                                {/* Qualification: */}
                                                Rating:
                                              </p>
                                              <div className="usr--rating">

                                                <Rating initialValue={Math.round(item?.ranting)} readonly="true" size="18" />

                                                {/* <span
                                                    className={`mr-1 fa fa-star ${item?.ranting >= 1
                                                      ? "checked"
                                                      : ""
                                                      }`}
                                                  />
                                                  <span
                                                    className={`mr-1 fa fa-star ${item?.ranting >= 2
                                                      ? "checked"
                                                      : ""
                                                      }`}
                                                  />
                                                  <span
                                                    className={`mr-1 fa fa-star ${item?.ranting >= 3
                                                      ? "checked"
                                                      : ""
                                                      }`}
                                                  />
                                                  <span
                                                    className={`mr-1 fa fa-star ${item?.ranting >= 4
                                                      ? "checked"
                                                      : ""
                                                      }`}
                                                  />
                                                  <span
                                                    className={`fa fa-star ${item?.ranting >= 5
                                                      ? "checked"
                                                      : ""
                                                      }`}
                                                  /> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <hr style={{ margin: "0px 0px" }}></hr>
                                  </li>
                                );

                              })
                            ) : (
                              <div className="text-center">
                                <img
                                  src="images/nodatafound.png"
                                  width="500px"
                                />
                              </div>
                            )}
                          </ul>
                        </div>
                        <div className="card-footer text-muted bg-white">
                          <a
                            href="#"
                            type="button"
                            className="btn main-btn w-100"
                            data-toggle="modal"
                            data-target="#addReview"
                          >
                            New Review
                          </a>
                        </div>
                      </div>

                    
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center">
                <img src="images/nodatafound-1.png" width="50%" />
                <div style={{ marginBottom: "30px" }}>
                  <Link to="/login">
                    For Update business information Login here
                  </Link>
                </div>
              </div>
            )
          ) : (
            <div className="text-center">
              <img src="images/nodatafound.png" width="50%" />
              <div style={{ marginBottom: "30px" }}>
                <Link to="/login">
                  For Update business information Login here
                </Link>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default ServiceDetails;
