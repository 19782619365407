import React, { useState, useEffect } from "react";
import Slider from "common/slider";
import { Rating } from "react-simple-star-rating";
import moment from "moment";
export default function JobsSelider(props) {
  const { jobsList } = props;
  const [abouthiden, setAbouthiden] = useState(true);
  const [currentindex, setcurrentindex] = useState();
  console.log(jobsList, 'xdxdxd')
  const seemore = (index) => {
    console.log("seemore -> index", index);
    //setAbouthiden(status);
    setcurrentindex(index);
  };

  const [title, setTitle] = useState("Title jobs");
  const [description, setDescription] = useState("Description jobs");
  const showJobs = ({ title, description }) => {
    setTitle(title);
    setDescription(description);
  };
  const correctjobdec = (description) => {
    const datahhh = description ? description : null;  
    console.log("🚀 ~ correctjobdec ~ datahhh:", datahhh)
    return datahhh.replace(/(\r\n|\n|\r)/g, '<br />');
  }

  return (
    <>
      <div className="card-body">
        <div className="row align-items-center business-externo">
          <div className="col-md-12 bus-detail-cont">
            <div className="srvc--containt card-body p-0">
              <div className="row">
                <div className="main--srvc-slider job-srvc-slider">
                  {jobsList?.length == 0 ? (
                   <>
                   <div className="text-center" style={{"backgroundColor" :"white","height":"400px"}}>
                     <img src="images/nodatafound.png" />
                      <span className="not-found-content">Ooops, no jobs found</span>
                      </div>
                  </>
                  ) : (
                    <Slider
                      arrows='true'
                      SliderArray={jobsList}
                      SliderItem={(item) => (
                        <div className="swiper-slide">

                          <div className="slid--box" style={{ width: "98%" }}>
                            <a>
                              <div className="slider--ser_img offer--slide-box">
                                <div className="iconsize">
                                  <div className="offer--slid-box">
                                    <h4 className="text--title">{item.item.title}</h4>
                                    <p className="text--date">
                                      <b>Effective Date : </b>
                                      {moment(item.item.createddate).format("MM-DD-YYYY")}
                                    </p>
                                    <p className="text--date">
                                      <b>Exp. Date : </b>
                                      {moment(item.item.expirationdate).format(
                                        "MM-DD-YYYY"
                                      )}
                                    </p>

                                    {/*   <p className={`text--date`}>
                                      {" "}
                                      {item.item.description}
                                    </p> */}

                                    <p className={`text--date ${currentindex === item?.index ? "" : "slid-dec--text"}`}>
                                      {" "}
                                      {/* {item.item.description} */}
                                      <div dangerouslySetInnerHTML={{__html: correctjobdec(item.item.description)}}></div> 
                                     </p>
                                    {
                                      item.item.description?.length > 100 ?
                                        currentindex === item?.index ?
                                          <div onClick={() => seemore('')} className="seemore">Less
                                          </div> :
                                          <div onClick={() => seemore(item?.index)} className="seemore">
                                            {'See More >>'}</div>
                                        : null
                                    }

                                    <p className="text--date" style={{ fontSize: "12px" }}>
                                      <b>Note : </b>
                                      <span style={{ color: '#6258d3' }}>To apply for this job simply click on Email and attach your resume</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      )}
                    />
                  )}
                </div>  </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
